<template>
    <!-- Rewrite when WF #346875 is done-->
    <div 
        class="p-0 mb-4" 
        v-if="setup_usescope !== undefined"
    >
        <div class="border-top-0 p-0 mb-4">
            <div class="d-flex align-items-center flex-row">
                <h6 class="mb-0 ms-1 text-body">{{ $t('Workflows') }}</h6>
                <span class="ms-auto">
                    <ODataLookup
                        :data-object="local_dsWFItemsLkp"
                        :bind="sel => { addRelatedWorkflow(sel) }"
                        :contextField="idPath_fieldName"
                    >
                        <OColumn field="ID" width="80" v-slot="{ row }">
                            <span :class="{ 'text-decoration-line-through': row.Closed }">{{ row.ID }}</span>
                        </OColumn>
                        <OColumn field="Title" :headerName="$t('Title')" width="400" />
                        <OColumn field="OrgUnit" :headerName="$t('Org Unit')" width="230" />
                        <OColumn field="CreatedBy" :headerName="$t('Created by')" width="250" />
                        <template #target="{target}">
                            <button class="btn btn-link text-decoration-none py-0" :ref="target">
                                <i class="bi bi-share" aria-hidden="true"></i> {{ $t('Connect existing workflow') }}
                            </button>
                        </template>
                    </ODataLookup>
                </span>
            </div>

            <div class="p-0">
                <ODataGrid
                    :data-object="local_dsMeasures"
                    hideGridMenu
                    class="py-0"
                    loadDataObject
                    hideMultiselectColumn
                    style="max-height:295px;min-height:295px;"
                    ref="relatedWF_Grid"
                >
                    <OColumn field="Workflow_ID" width="65" sortable read-only :headerName="$t('ID')"  :headerTitle="$t('Workflow ID')" :cellrendererparams="{href:'/nt/workflow?ID={{Workflow_ID}}', target:'_blank'}" v-slot="{row,column}">
                        <OLink :modelValue="row" :column="column" :class="{'text-decoration-line-through':row.Closed}"></OLink>
                    </OColumn>
                    <OColumn field="Title" :headerTitle="$t('Title')" :headerName="$t('Title')" width="285" sortable read-only />
                    <OColumn field="Step" :headerTitle="$t('Step')" :headerName="$t('Step')" width="200" sortable read-only />
                    <OColumn field="StepResp" :headerTitle="$t('Step Responsible')" :headerName="$t('Step Responsible')" width="213" sortable read-only />
                    <!-- <OColumn field="Process" :headerTitle="$t('Process')" :headerName="$t('Process')" hide sortable read-only /> -->
                    <OColumn field="DueDate" :headerTitle="$t('Due date')" :headerName="$t('Due date')" hide sortable read-only />
                    <OColumn field="CatAorB" :headerTitle="$t('Category A or B')" :headerName="$t('Category A or B')" hide sortable read-only />
                    <!-- <OColumn field="IsPunchItem" :headerTitle="$t('Is Punch Item')" :headerName="$t('Is Punch Item')" hide sortable read-only /> -->
                    <!-- <OColumn field="RelatedObjects" :headerTitle="$t('Related objects')" :headerName="$t('Related objects')" hide sortable read-only /> -->
                    <!-- <OColumn field="Conclusion" :headerTitle="$t('Conclusion')" :headerName="$t('Conclusion')" hide sortable read-only /> -->
                    <OColumn field="CreatedBy" :headerTitle="$t('Created by name')" :headerName="$t('Created by')" hide sortable read-only />
                    <OColumn field="Created" :headerTitle="$t('Created date')" :headerName="$t('Created date')" hide sortable read-only />
                    <OColumn field="ClosedBy" :headerTitle="$t('Closed by name')" :headerName="$t('Closed by')" hide sortable read-only />
                    <OColumn field="Closed" :headerTitle="$t('Closed')" :headerName="$t('Closed')" hide sortable read-only />
                </ODataGrid>
                
                <OTabs class="mt-2">
                    <OTab :title="$t('Workflow details')" active>
                        <iframe ref="iFrame" style="min-height:70vh" v-if="!isLoading && currentWF" :src="`/nt/workflow?ID=${currentWF}&HideNav=true`" class="w-100 h-100" ></iframe>
                    </OTab>
                    <OTab :title="$t('Create new')">
                        <div class="p-2" v-if="!renderCreateNewApp">
                            <span class="fw-bold">{{ $t('Promoted processes') }}</span>
                            <div class="d-flex justify-content-center" v-if="local_dsPromotedProcesses.state.isLoading">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div class="mb-2">
                                <button class="btn btn-sm btn-outline-primary mx-1 my-1" v-for="(process, index) in local_dsPromotedProcesses.data" :process_id="process.Process_ID" :key="index" @click="el => { createWorkflow(el) }">
                                    {{ $t(process.Process) }}
                                </button>    
                            </div>
                            <span>
                                {{ $t('Select one of the above [promoted/default] process or click') }}
                                <button class="btn btn-sm btn-outline-primary mx-1" process_id="null" @click="el => { createWorkflow(el) }">
                                    {{ $t('Create workflow') }}
                                </button>
                                {{ $t('to use another process') }}
                            </span>
                        </div>
                        <iframe class="w-100 h-100 mt-0" style="min-height:50vh" v-if="renderCreateNewApp" :src="newWorkflowUrl"></iframe>
                    </OTab>
                </OTabs>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { onMounted, onUnmounted, ref } from 'vue';
    import { $t } from 'o365-utils';
    // import IFrameComp from 'o365.vue.components.DetailsIframe.vue';
    import { API } from 'o365-modules';

    const procConnectWorkflow = getOrCreateProcedure({ id:"procConnectWorkflow", procedureName: "astp_Workflow_ItemsConnect" });

    const props = defineProps({
        "riskID": {
            type: Number, 
            required: true
        },
        "riskOrgUnitID": {
            type: Number, 
            required: true
        }
    });
    
    const isLoading = ref(false);
    const relatedWF_Grid = ref(null);
    const iFrame = ref(null);
    const currentWF = ref(null);
    const newWorkflowUrl = ref(null);
    const renderCreateNewApp = ref(false);

    const cleanup_tokens = [];
    let idPath_fieldName = null;
    let local_dsMeasures = null;
    let local_dsWFItemsLkp = null;
    const setup_usescope = ref(undefined);

    async function initialize() {
        const database_setup = await API.request({ // Retrieving database setup row
            requestInfo: '/api/data/stbl_Database_Setup',
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'viewName': 'stbl_Database_Setup',
                'distinctRows': false,
                'skip': 0,
                'fields': [
                    { name: "UseScope", type: "bit"}, 
                ],
                'loadRecents': false,
                'masterDetailString': null,
                'filterString': null,
                'operation': 'retrieve'
            })
        });
        setup_usescope.value = database_setup[0].UseScope ?? false;

        const measures_viewName = setup_usescope.value ? "aviw_RAMS_ScopeMeasures" : "aviw_RAMS_Measures";
        local_dsMeasures = getOrCreateDataObject({
            id: 'dsMeasures' + crypto.randomUUID(),
            viewName: measures_viewName,
            maxRecords: 25,
            selectFirstRowOnLoad: true,
            allowUpdate: false,
            allowInsert: false,
            allowDelete: false,
            whereClause: "Risk_ID = " + props.riskID,
            fields:
                [
                    {name: "Created", type: "date" }, 
                    {name: "Risk_ID", type: "int" },
                    {name: "Workflow_ID", type: "int" },
                    {name: "ProbabilityImpact", type: "int" },
                    {name: "ConsequenceImpact", type: "int" },
                    {name: "Impact", type: "string" },
                    {name: "ClosedOrMoved", type: "date" },
                    {name: "Closed", type: "date" },
                    {name: "Item_ID", type: "int" },
                    // {name: "ID", type: "int" },
                    {name: "Title", type: "string" },
                    {name: "Step", type: "string" },
                    {name: "Severity", type: "string" },
                    {name: "StepResp", type: "string" },
                    {name: "OrgUnit_ID", type: "int" },
                    {name: "OrgUnit", type: "string" },
                    {name: "CreatedBy", type: "string" },
                    {name: "ClosedBy", type: "string" },
                    {name: "DueDate", type: "date" },
                    {name: "CatAorB", type: "string" }
                ]
        });

        // Adding cancellation tokens to array
        cleanup_tokens.push(
            // When changing which WF to see details for, message is posted for making only the WF datasource refresh and not the whole iframe
            local_dsMeasures.on("CurrentIndexChanged", async () => {
                isLoading.value = true;
                currentWF.value = local_dsMeasures.current.Workflow_ID;
                console.log("curr WF ID: " + currentWF.value)
                if(currentWF.value) {
                    console.log("før post")
                    // await iFrame?.value?.post({
                        // workflowId: currentWF.value
                    // });
                }
                isLoading.value = false; 
            })
        )
        cleanup_tokens.push(
            local_dsMeasures.on("DataLoaded", () => {
                if(local_dsMeasures.data.length === 0) { currentWF.value = null; }
            })
        )
        
        const measuresLkp_viewName = setup_usescope.value ? "aviw_Scope_Workflows" : "aviw_Workflow_Items";
        idPath_fieldName =  setup_usescope.value ? "OrgUnitIdPath" : "IdPath";
        local_dsWFItemsLkp = getOrCreateDataObject({
            id: 'dsWFItemsLkp' + crypto.randomUUID(),
            viewName: measuresLkp_viewName,
            maxRecords: 25,
            fields:
                [
                    {name: "ID", type: "int" },
                    {name: "Closed", type: "date" },
                    {name: "OrgUnit", type: "string" },
                    {name: "CreatedBy", type: "string" },
                    {name: "Title", type: "string" },
                    {name: idPath_fieldName, type: "string" }
                ]
        })
    }
    initialize();

    const local_dsPromotedProcesses = getOrCreateDataObject({
        id: 'dsPromotedProcesses' + crypto.randomUUID(),
        viewName: 'aviw_RAMS_Processes',
        maxRecords: 25,
        fields:
            [
                {name: "ID", type: "int" },
                {name: "Process_ID", type: "date" },
                {name: "Process", type: "string" },
                {name: "Closed", type: "string" }
            ]
    })

    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    
    const addRelatedWorkflow = (workflow) => {
        procConnectWorkflow.execute({
            Item_ID: workflow.ID,
            Rams_ID: props.riskID,
            UseScope: Number(setup_usescope.value)
        }).then(() => {
            local_dsMeasures.load();
        });
    }

    onMounted(async () => {
        await local_dsPromotedProcesses.load();
    })

    onUnmounted(() => {
        cleanup_tokens.forEach((ct) => {
            ct();
        });
    })

    // Listen to message from child window
    eventer(messageEvent,function(e) {
        const data = e["data"];
        console.log("message recieved: " + data);
        if(data === 'workflowCreated') {
            local_dsMeasures.load().then(() => {
                renderCreateNewApp.value = false;
            });
        } else if(data === 'workflowCancelled') {
            renderCreateNewApp.value = false;
        }
    }, false);
        
    const createWorkflow = (element) => {
        const process_value = element.srcElement.attributes.process_id.value;

        if(process_value !== 'null') {
            newWorkflowUrl.value = `/nt/workflow-new?process-id=${process_value}&Context=${props.riskOrgUnitID}&rams-id=${props.riskID}&Redirect=postmessage&HideNav=true`;
        } else {
            newWorkflowUrl.value = `/nt/workflow-new?Context=${props.riskOrgUnitID}&rams-id=${props.riskID}&Redirect=postmessage&HideNav=true`; 
        }

        renderCreateNewApp.value = true;
    }
</script>